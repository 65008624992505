.imageWrapper {
  overflow: hidden;
  & .button {
    opacity: 0;
    transition: 200ms all;
  }
  &:hover .button {
    opacity: 1;
  }
  & .overlay {
    display: none;
  }
  &:hover .overlay {
    display: block;
  }
}

.inputLabel {
  padding-bottom: 0.5em;
}

.requiredButton {
  color: var(--mantine-color-error);
}

@import "src/shared/scss/variables";

.navbar {
  height: 100vh;
  width: 20vw;
  position: fixed;
  top: 0;
  left: 0;
  padding: var(--mantine-spacing-md);
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--mantine-color-dark-4);
  background: var(--mantine-color-dark-7);
  color: var(--mantine-color-beige);

  @media (max-width: $mantine-breakpoint-md) {
    display: none;
  }
}

.navbarMain {
  flex: 1;
}

.header {
  height: calc(var(--mantine-header-height) - var(--mantine-spacing-md));
  padding-bottom: var(--mantine-spacing-md);
  border-bottom: 1px solid var(--mantine-color-beige-light);
}

.menu {
  padding: 3vh 0;
}

.footer {
  padding-top: var(--mantine-spacing-md);
  margin-top: var(--mantine-spacing-md);
  border-top:
    1px solid var(--mantine-color-gray-3),
    var(--mantine-color-dark-4);
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: var(--mantine-font-size-sm);
  color: var(--mantine-color-dark-1);
  padding: var(--mantine-spacing-md) var(--mantine-spacing-sm);
  border-radius: var(--mantine-radius-md);
  font-weight: 700;
  cursor: pointer;

  &:hover {
    background-color: var(--mantine-color-dark-6);
    color: var(--mantine-color-white);

    & .linkIcon {
      color: var(--mantine-color-white);
    }
  }

  &[data-active] {
    &,
    &:hover {
      background-color: var(--mantine-color-gold-light);
      color: var(--mantine-color-gold-5);

      & .linkIcon {
        color: var(--mantine-color-blue-beige);
      }
    }
  }
}

.linkIcon {
  color: var(--mantine-color-dark-2);
  margin-right: var(--mantine-spacing-sm);
  width: 25px;
  height: 25px;
}

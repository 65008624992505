@import "./variables";

@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin centerForAbsolute {
  position: sticky;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin fixed-top {
  position: fixed;
  top: 0;
  left: 0;
}

@mixin sticky() {
  position: sticky;
  top: calc(var(--mantine-header-height) + var(--mantine-spacing-lg));

  &[data-bottom="true"] {
    bottom: calc(var(--footer-height-mobile) + var(--mantine-spacing-lg));
    @media (min-width: $mantine-breakpoint-md) {
      bottom: calc(var(--mantine-footer-height) + var(--mantine-spacing-lg));
    }
  }
}

@mixin animation-appearance() {
  opacity: 0;
  display: none;
  transform: translate(0, 100%);
  transition: all 200ms ease 0ms;

  &[data-active="true"] {
    opacity: 1;
    transform: translate(0, 0);
  }

  &[data-activate="true"] {
    display: block;
  }
}

@mixin scrollable-flex($media: $mantine-breakpoint-md) {
  @media (min-width: $media) {
    max-height: $scrollable-flex-max-height;
    overflow: auto;
    flex-grow: 0;
  }
}

@mixin absolute($top: auto, $left: auto, $right: auto, $bottom: auto) {
  position: absolute;
  top: $top;
  left: $left;
  right: $right;
  bottom: $bottom;
}

@mixin flex($justify: flex-start, $align: top) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
}

@mixin bootstrap-column() {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: visible;
}

@mixin bootstrap-row($spacing: var(--mantine-spacing-lg)) {
  justify-content: stretch;
  display: flex;
  flex-wrap: wrap;

  & > div {
    margin: 0 auto 0 auto;
    padding-bottom: $spacing;
  }

  & > .grow {
    flex-grow: 1;
  }

  & > div.withoutPb {
    padding-bottom: 0;
  }

  & > div.withoutMAuto {
    margin: 0 calc($spacing / 2) 0 calc($spacing / 2);
  }

  & > .col12 {
    width: calc(100% - $spacing);
  }

  & > .col8 {
    width: calc(66.6% - $spacing);
  }

  & > .col6 {
    width: calc(50% - $spacing);
  }

  & > .col4 {
    width: calc(33.3% - $spacing);
  }

  & > .col3 {
    width: calc(25% - $spacing);
  }

  & > div.withoutSpacing {
    margin: 0;
  }

  & > .col12.withoutSpacing {
    width: 100%;
  }

  & > .col8.withoutSpacing {
    width: 66.6%;
  }

  & > .col6.withoutSpacing {
    width: 50%;
  }

  & > .col4.withoutSpacing {
    width: 33.3%;
  }

  & > .col3.withoutSpacing {
    width: 25%;
  }

  @media (max-width: $mantine-breakpoint-md) {
    & > .col12Md {
      width: calc(100% - $spacing);
    }

    & > .col8Md {
      width: calc(100% - $spacing);
    }

    & > .col6Md {
      width: calc(100% - $spacing);
    }

    & > .col4Md {
      width: calc(100% - $spacing);
    }

    & > .col3Md {
      width: calc(50% - $spacing);
    }

    & > .col12Md.withoutSpacing {
      width: 100%;
    }

    & > .col8Md.withoutSpacing {
      width: 100%;
    }

    & > .col6Md.withoutSpacing {
      width: 100%;
    }

    & > .col4Md.withoutSpacing {
      width: 100%;
    }

    & > .col3Md.withoutSpacing {
      width: 50%;
    }

    & > .col8 {
      width: 100%;
    }

    & > .col6 { 
      width: 100%;
    }

    & > .col4 {
      width: 100%;
    }
  }

  @media (max-width: $mantine-breakpoint-sm) {
    & > .col12Sm {
      width: calc(100% - $spacing);
    }

    & > .col8Sm {
      width: calc(100% - $spacing);
    }

    & > .col6Sm {
      width: calc(100% - $spacing);
    }

    & > .col4Sm {
      width: calc(100% - $spacing);
    }

    & > .col3Sm {
      width: calc(100% - $spacing);
    }

    & > .col12Sm.withoutSpacing {
      width: 100%;
    }

    & > .col8Sm.withoutSpacing {
      width: 100%;
    }

    & > .col8Sm.withoutSpacing {
      width: 100%;
    }

    & > .col6Sm.withoutSpacing {
      width: 100%;
    }

    & > .col4Sm.withoutSpacing {
      width: 100%;
    }

    & > .col3Sm.withoutSpacing {
      width: 100%;
    }
  }

  @media (max-width: $mantine-breakpoint-lg) {
    & > .col12Lg {
      width: calc(100% - $spacing);
    }

    & > .col8Lg {
      width: calc(100% - $spacing);
    }

    & > .col6Lg {
      width: calc(100% - $spacing);
    }

    & > .col4Lg {
      width: calc(100% - $spacing);
    }

    & > .col3Lg {
      width: calc(100% - $spacing);
    }

    & > .col12Lg.withoutSpacing {
      width: 100%;
    }

    & > .col8Lg.withoutSpacing {
      width: 100%;
    }

    & > .col6Lg.withoutSpacing {
      width: 100%;
    }

    & > .col4Lg.withoutSpacing {
      width: 100%;
    }

    & > .col3Lg.withoutSpacing {
      width: 100%;
    }
  }

  @media (max-width: $mantine-breakpoint-xl) {
    & > .col12Xl {
      width: calc(100% - $spacing);
    }

    & > .col8Xl {
      width: calc(100% - $spacing);
    }

    & > .col6Xl {
      width: calc(100% - $spacing);
    }

    & > .col4Xl {
      width: calc(100% - $spacing);
    }

    & > .col3Xl {
      width: calc(100% - $spacing);
    }

    & > .col12Xl.withoutSpacing {
      width: 100%;
    }

    & > .col8Xl.withoutSpacing {
      width: 100%;
    }

    & > .col6Xl.withoutSpacing {
      width: 100%;
    }

    & > .col4Xl.withoutSpacing {
      width: 100%;
    }

    & > .col3Xl.withoutSpacing {
      width: 100%;
    }
  }
}

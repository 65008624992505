.wrapper {
  & *[data-active="true"] {
    color: var(--mantine-color-dark-9);
  }
}

.select {
  & label {
    padding-bottom: 0.5em;
  }
}

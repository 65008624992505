:root {
  --mantine-breakpoint-xs: 36em;
  --mantine-breakpoint-sm: 48em;
  --mantine-breakpoint-md: 62em;
  --mantine-breakpoint-lg: 75em;
  --mantine-breakpoint-xl: 88em;
}

$mantine-breakpoint-xs: 36em;
$mantine-breakpoint-sm: 48em;
$mantine-breakpoint-md: 62em;
$mantine-breakpoint-lg: 75em;
$mantine-breakpoint-xl: 88em;
$mantine-padding-main: 10em;
$scrollable-flex-max-height: 40rem;

:root {
  --footer-height-mobile: calc(1.3 * var(--mantine-footer-height));
}

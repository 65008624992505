@import "src/shared/scss/variables";
@import "src/shared/scss/mixins";
.wrapper {
  height: var(--mantine-footer-height);
  position: fixed;
  bottom: 0;
  left: 20vw;
  width: 80vw;
  z-index: 200;
  border-top: 1px solid var(--mantine-color-dark-5);
  background: var(--mantine-color-dark-7);
  @media (max-width: $mantine-breakpoint-md) {
    display: none;
  }

  & .row {
    height: 100%;
    align-items: center;
    @include bootstrap-row();
  }

  & .footerLeftItems {
    height: 100%;
  }

  & .footerRightItems {
    display: flex;

    .footerCompanyName {
      text-wrap: nowrap;
      display: flex;
      align-items: center;
      min-width: 115px;
      margin-right: 2em;
    }
  }
}



.overlay {
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 200ms;

  &:hover {
    opacity: 1;
  }
}


html,
body {
  height: initial !important; /* mantine reset */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--mantine-color-dark-9);
  /* overflow-x: hidden; */
}

#root {
  /* overflow-x: hidden; */
}

*::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
    height: 0;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
}

button:disabled {
  color: var(--mantine-color-dark-3) !important;
}

input {
  border: none !important;
}

input[data-error="true"] {
  color: var(--mantine-color-dark-0) !important;
}

input[data-error="true"]::placeholder {
  color: var(--mantine-color-dark-3) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

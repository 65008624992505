.input {
  & label {
    padding-bottom: 0.5em;
  }
  & textarea {
    border: none;
    outline: none;
    resize: none;
  }
  & ::placeholder {
    color: var(--mantine-color-dark-3);
  }
}

.button[data-loading="true"]::before {
  position: static;
}

@keyframes rotate {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.icon {
  &[data-active="true"] {
    animation-name: rotate;
    animation-duration: 0.3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
}

@import "src/shared/scss/variables";

.wrapper {
  width: 80vw;
  margin-left: 20vw;

  @media (max-width: $mantine-breakpoint-md) {
    width: 100vw;
    margin-left: 0;
  }
}

.header {
  position: sticky;
  top: 0;
  background-color: var(--mantine-color-body);
  transition: box-shadow 150ms ease;
  z-index: 100;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: calc(0.0625rem * var(--mantine-scale)) solid var(--_table-border-color);
  }
}

.scrolled {
  box-shadow: var(--mantine-shadow-sm);
}

@import "/src/shared/scss/mixins.scss";
@import "/src/shared/scss/variables.scss";

.row {
  @include bootstrap-row();
  @media (max-width: $mantine-breakpoint-md) {
    width: 100%;
  }
}

.column {
  @include bootstrap-column();
}

.xlScrollable {
  @include scrollable-flex($mantine-breakpoint-xl);
}

.lgScrollable {
  @include scrollable-flex($mantine-breakpoint-lg);
}

.mdScrollable {
  @include scrollable-flex($mantine-breakpoint-md);
}

.smScrollable {
  @include scrollable-flex($mantine-breakpoint-sm);
}

.xsScrollable {
  @include scrollable-flex($mantine-breakpoint-xs);
}

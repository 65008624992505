@import "src/shared/scss/variables";

.footer {
  position: fixed;

  height: var(--footer-height-mobile);
  width: 100vw;
  bottom: 0;
  left: 0;
  z-index: 300;

  @media (min-width: $mantine-breakpoint-md) {
    display: none;
  }
}

.navbar {
  width: 100%;
  height: 100%;

  background: var(--mantine-color-dark-7);
  border-top: 1px solid var(--mantine-color-dark-5);
}

.link {
  background-color: transparent;
  color: var(--mantine-color-blue-beige);
  font-size: var(--mantine-font-size-xl);
  font-weight: 400;

  &:hover {
    background-color: var(--mantine-color-dark-5);

    & .linkIcon {
      color: var(--mantine-color-white);
    }
  }

  &[data-active] {
    background-color: var(--mantine-color-gold-light);
    color: var(--mantine-color-gold-5);

    & .linkIcon {
      color: var(--mantine-color-blue-beige);
    }
  }
}

.linkIcon {
  width: 40px;
  height: 40px;
  color: var(--mantine-color-white);
}

.mainLink {
  transform: scale(1.1);
  color: var(--mantine-color-blue-beige);
  background-color: var(--mantine-color-gold-filled);

  // background-color: transparent;

  & .linkIcon {
    color: var(--mantine-color-black);
  }
}
